import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form, FormControl } from 'react-bootstrap';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { ProjectDto } from '@portal/client-portal-api-model';

interface ProjectSearchProps {
  projects: ProjectDto[];
  setFilteredProjects: (projects: ProjectDto[]) => void;
}

interface SearchFormValues {
  name: string;
}

const initialValues: SearchFormValues = {
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string(),
});

export default function ProjectSearch({ projects, setFilteredProjects }: ProjectSearchProps) {
  const handleSearch = useCallback(
    (value: string) => {
      const debouncedSearch = debounce((searchValue: string) => {
        if (searchValue) {
          setFilteredProjects(
            projects.filter((project) =>
              project.name.toLowerCase().includes(searchValue.toLowerCase())
            )
          );
        } else {
          setFilteredProjects(projects);
        }
      }, 300);
      debouncedSearch(value);
    },
    [projects, setFilteredProjects]
  );

  const { handleSubmit, handleChange, values } = useFormik<SearchFormValues>({
    initialValues,
    onSubmit: (values) => {
      handleSearch(values.name);
    },
    validationSchema,
  });

  useEffect(() => {
    handleSearch(values.name);
  }, [values.name, handleSearch]);

  return (
    <Form
      className="p-2"
      inline
      onSubmit={handleSubmit}
    >
      <FormControl
        type="text"
        name="name"
        placeholder="Search projects..."
        className="w-100"
        onChange={handleChange}
      />
    </Form>
  );
}
