import moment from 'moment';
import { Moment } from 'moment-business-days';

export const getDaysCurrentWeek = (date: Moment): Moment[] => {
  const weekStart = date.startOf('isoWeek');
  const days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days'));
  }
  return days;
};
