import { useState } from 'react';
import { Moment } from 'moment-business-days';
import moment from 'moment';
import { getDaysCurrentWeek } from '../functions/getDaysCurrentWeek';

export function useDaysOfWeek(): [
  Moment[],
  React.Dispatch<React.SetStateAction<Moment[]>>,
] {
  const [daysOfWeek, setDaysOfWeek] = useState<Moment[]>([
    ...getDaysCurrentWeek(moment()),
  ]);
  return [daysOfWeek, setDaysOfWeek];
}
