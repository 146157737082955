import { ReportsFiltersDto } from '@portal/client-portal-api-model';
import { Loader, useEmployees } from '@portal/frontend/react';
import {
  customTimeRangeOption,
  defaultTimeRangeOption,
  filterOptionByLabel,
  getFirstDayOfMonth,
  getFirstDayOfWeek,
  getFirstDayOfYear,
  getLastDayOfMonth,
  getLastDayOfWeek,
  getLastDayOfYear,
  SelectOption,
  TimeRange,
  timeSelectRangeOptions,
} from '@portal/frontend/utils';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useProjects } from '../../hooks/useProjects';

interface ReportsFormProps {
  onSubmitHandler: (values: ReportsFiltersDto, csvFileName: string) => void;
}

const initialTouched = {
  employeesIds: false,
  finishDate: false,
  startDate: false,
  timeRange: false,
  projectsIds: false,
};

export const ReportsForm: FC<ReportsFormProps> = ({ onSubmitHandler }) => {
  const { employees } = useEmployees();

  const { projects } = useProjects();

  const {
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setTouched,
  } = useFormik<
    ReportsFiltersDto & {
      timeRangeOption: SelectOption;
    }
  >({
    initialValues: {
      employeeIds: [],
      finishDate: moment(getLastDayOfMonth(moment().toDate())).format(
        'YYYY-MM-DD',
      ),
      startDate: moment(getFirstDayOfMonth(moment().toDate())).format(
        'YYYY-MM-DD',
      ),
      projectIds: [],
      timeRangeOption: defaultTimeRangeOption,
    },
    initialTouched: initialTouched,
    onSubmit: (values) => {
      const employeeNames = employees
        .filter((employee) => values.employeeIds.includes(employee.id))
        .map((employee) => employee.name)
        .join(', ');
      const projectNames = projects
        .filter((project) => values.projectIds.includes(project.id))
        .map((project) => project.name)
        .join(', ');

      let csvFileName = `timesheet_${values.startDate}/${values.finishDate}`;
      if (employeeNames) csvFileName += `_${employeeNames}`;
      if (projectNames) csvFileName += `_${projectNames}`;
      onSubmitHandler(
        {
          employeeIds: values.employeeIds,
          projectIds: values.projectIds,
          startDate: values.startDate,
          finishDate: values.finishDate,
        },
        csvFileName,
      );
    },
  });

  useEffect(() => {
    if (touched.startDate || touched.finishDate) {
      setTouched(initialTouched);
    }
  }, [setFieldValue, touched.startDate, touched.finishDate, setTouched]);

  useEffect(() => {
    setTouched(initialTouched);
  }, [setTouched]);

  if (!employees || !projects) {
    return <Loader fullScreen={true} />;
  }

  const updateDates = (timeRange: TimeRange) => {
    const NOW = new Date();

    switch (timeRange) {
      case TimeRange.THIS_WEEK:
        setFieldValue(
          'startDate',
          moment(getFirstDayOfWeek(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(getLastDayOfWeek(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.LAST_WEEK:
        setFieldValue(
          'startDate',
          moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(
            getLastDayOfWeek(
              new Date(NOW.setDate(NOW.getDate() - 7)),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.THIS_MONTH:
        setFieldValue(
          'startDate',
          moment(getFirstDayOfMonth(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(getLastDayOfMonth(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.LAST_MONTH:
        setFieldValue(
          'startDate',
          moment(
            getFirstDayOfMonth(
              new Date(NOW.getFullYear(), NOW.getMonth() - 1, 1),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(
            getLastDayOfMonth(
              new Date(NOW.getFullYear(), NOW.getMonth() - 1, 1),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.THIS_YEAR:
        setFieldValue(
          'startDate',
          moment(getFirstDayOfYear(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(getLastDayOfYear(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.LAST_YEAR:
        setFieldValue(
          'startDate',
          moment(
            getFirstDayOfYear(
              new Date(NOW.getFullYear() - 1, 0, 1),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(
            getLastDayOfYear(
              new Date(NOW.getFullYear() - 1, 0, 1),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.CUSTOM:
        setFieldValue('startDate', values.startDate);
        setFieldValue('finishDate', values.finishDate);
        break;
    }
  };

  const projectOptions = projects
    .filter((project) => !project.deletedAt)
    .map((project) => ({
      label: project.name,
      value: project.id,
    }));

  const employeeOptions = employees.map((employee) => ({
    label: `${employee.name} ${employee.surname}`,
    value: employee.id,
  }));

  const animatedComponents = makeAnimated();

  return (
    <Form className="w-100" onSubmit={handleSubmit}>
      <div className="reports-form-container">
        <Col className="reports-from-container-item">
          <Form.Group>
            <Form.Label>Select Projects</Form.Label>
            <Select<SelectOption, true>
              closeMenuOnSelect={false}
              defaultValue={[]}
              components={animatedComponents}
              isMulti
              menuPortalTarget={document.body}
              menuPosition="fixed"
              isSearchable
              filterOption={filterOptionByLabel}
              options={projectOptions}
              onChange={(selectedOptions) => {
                setFieldValue(
                  'projectIds',
                  selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [],
                );
              }}
            />
          </Form.Group>
        </Col>

        <Col className="reports-from-container-item">
          <Form.Group>
            <Form.Label>Select Employees</Form.Label>
            <Select<SelectOption, true>
              closeMenuOnSelect={false}
              defaultValue={[]}
              isMulti
              isSearchable
              menuPortalTarget={document.body}
              menuPosition="fixed"
              filterOption={filterOptionByLabel}
              options={employeeOptions}
              components={animatedComponents}
              onChange={(selectedOptions) => {
                setFieldValue(
                  'employeeIds',
                  selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [],
                );
              }}
            />
          </Form.Group>
        </Col>

        <Col className="reports-from-container-item">
          <Form.Group>
            <Form.Label>Select Time range</Form.Label>
            <Select<SelectOption, false>
              closeMenuOnSelect={true}
              options={timeSelectRangeOptions}
              value={values.timeRangeOption}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              onChange={(selectedOption) => {
                setFieldValue('timeRangeOption', selectedOption);
                updateDates(selectedOption.value as TimeRange);
              }}
            />
          </Form.Group>
        </Col>

        <Col className="reports-from-container-item">
          <Form.Group>
            <Form.Label>&nbsp;</Form.Label>
            <Form.Control
              type="date"
              name={'startDate'}
              value={values.startDate}
              required
              onChange={(...args) => {
                setFieldValue('timeRangeOption', customTimeRangeOption);
                handleChange(...args);
              }}
            />
          </Form.Group>
        </Col>

        <Col className="reports-from-container-item">
          <Form.Group>
            <Form.Label>&nbsp;</Form.Label>
            <Form.Control
              type="date"
              name={'finishDate'}
              value={values.finishDate}
              required
              onChange={(...args) => {
                setFieldValue('timeRangeOption', customTimeRangeOption);
                handleChange(...args);
              }}
            />
          </Form.Group>

          <Button
            style={{ width: '100%', marginBottom: '10px' }}
            type="submit"
            variant="primary"
          >
            Show results
          </Button>
        </Col>
      </div>
    </Form>
  );
};
