export enum AppRoutes {
  HOME = '/',
  SIGNIN = '/auth/signin',
  PROFILE = '/profile',

  /**
   * Developer portal routes
   */
  DEVELOPER_PORTAL = '/developer-portal',
  REPORT = '/report',

  /**
   * Silevis Portal routes
   */
  REPORTS = '/reports',
  WORKITEMS = '/workitems',
  EMPLOYEES = '/employees',
  PROJECT_SETTINGS = '/projectSettings',
  BANK_HOLIDAYS = '/bankHolidays',
  EMPLOYEE_WORKLOGS = '/employeeWorklogs',
}
