import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectDto } from '@portal/client-portal-api-model';
import { Table as ProjectIcon } from 'react-bootstrap-icons';
import classNames from 'classnames';
import ProjectSearch from './shared/ProjectSearch';
import { AppRoutes } from '@portal/frontend/utils';
import useScrollRestoration from '../hooks/useScrollRestoration';

interface SidebarProps {
  projects: ProjectDto[];
}

export const Sidebar: FC<SidebarProps> = ({ projects }) => {
  useScrollRestoration('sidebar-scroll');

  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();

  const [filteredProjects, setFilteredProjects] = useState(projects);

  const handleOnProjectClick = (id: string) => {
    navigate(`${AppRoutes.WORKITEMS}/${id}`);
  };

  return (
    <div className="sidebar pb-2">
      <div className="sidebar-title text-align-center">Projects</div>

      <ProjectSearch
        projects={projects}
        setFilteredProjects={setFilteredProjects}
      />

      <div id="sidebar-scroll" className="sidebar-project-list overflow-auto">
        {filteredProjects.map((project) => (
          <div
            onClick={() => handleOnProjectClick(project.id)}
            key={project.id}
            className={classNames('sidebar-project-list-item', {
              active: project.id === projectId,
            })}
          >
            <span className="pr-2">
              <ProjectIcon />
            </span>
            {project.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
