import { Employee, Project } from '@portal/database-entities';

export const flattenProjectToWorkItemsReport = (report: Project[]) =>
  report.flatMap((p) =>
    p.workItems.flatMap((w) =>
      w.timetableEntries.flatMap((entry) => ({
        projectName: p.name,
        employeeName: entry.collaborator.employee.name,
        employeeSurname: entry.collaborator.employee.surname,
        employeeCode: entry.collaborator.employee.code,
        workItem: w.name,
        entryDate: entry.date,
        entryHours: entry.hours,
        entryDescription: entry.description,
      }))
    )
  );

export const flattenProjectToPeopleReport = (report: Project[]) =>
  report.flatMap((p) =>
    p.collaborators.flatMap((c) =>
      c.timetableEntries.flatMap((entry) => ({
        projectName: p.name,
        employeeName: c.employee.name,
        employeeSurname: c.employee.surname,
        employeeCode: c.employee.code,
        workItem: entry.workItem.name,
        entryDate: entry.date,
        entryHours: entry.hours,
        entryDescription: entry.description,
      }))
    )
  );

export const flattenPeopleToProjectReport = (report: Employee[]) =>
  report.flatMap((employee) =>
    employee.collaborators.flatMap((c) =>
      c.timetableEntries.flatMap((entry) => ({
        projectName: c.project.name,
        employeeName: employee.name,
        employeeSurname: employee.surname,
        employeeCode: employee.code,
        workItem: entry.workItem.name,
        entryDate: entry.date,
        entryHours: entry.hours,
        entryDescription: entry.description,
      }))
    )
  );
