import React, { useCallback, useState } from 'react';
import { Tabs, Tab, Col } from 'react-bootstrap';
import CollaboratorsTab from './CollaboratosTabs';
import RolesTab from './RolesTab';
import ProjectList from './ProjectList';

export const ProjectSettings = () => {
  const [key, setKey] = useState('roles');
  const [selectedProjectId, setSelectedProjectId] = useState<string>();

  const onChangeProject = useCallback(
    (id: string) => setSelectedProjectId(id),
    [setSelectedProjectId],
  );

  return (
    <>
      <Col md={3} xs={12} className="h-100">
        <ProjectList
          selectedProjectId={selectedProjectId}
          onChangeProject={onChangeProject}
        />
      </Col>

      <Col md={9} xs={12} className="p-3 d-flex flex-column h-100">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(key) => setKey(key)}
        >
          <Tab
            className="h-100 pt-3"
            eventKey="roles"
            title="Roles"
            mountOnEnter
            unmountOnExit
          >
            <RolesTab projectId={selectedProjectId} />
          </Tab>

          <Tab
            className="h-100 pt-3"
            eventKey="Collaborators"
            title="Collaborators"
            mountOnEnter
            unmountOnExit
          >
            <CollaboratorsTab projectId={selectedProjectId} />
          </Tab>
        </Tabs>
      </Col>
    </>
  );
};
