import { ReactGrid, CellChange, Column, Id } from '@silevis/reactgrid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios, { AxiosResponse } from 'axios';
import { Button } from 'react-bootstrap';
import { sendRequest, sortByExtId } from '@portal/frontend/utils';
import {
  CreateWorkItemDto,
  UpdateWorkItemDto,
  UpdateWorkItemRoleHoursPayloadDto,
  UpdateWorkItemRoleHoursPlannedHoursDto,
  UpdateWorkItemsDto,
  UpsertWorkItemRoleHoursWithPlannedHoursResponseDto,
} from '@portal/client-portal-api-model';
import {
  ActionSpinner,
  ArchiveButtonCellTemplate,
  CustomDropdownCellTemplate,
  ExtendedTextCellTemplate,
  LoadingOverlay,
  makeCellsNonEditable,
  ToggleSwitch,
} from '@portal/frontend/react';
import { ColumnsIds } from '../enums/ColumnsIds';
import { WorkItem } from '../interfaces/WorkItem';
import Endpoint from '../enums/apiEndpoints';
import { Role } from '../interfaces/Role';
import { getColumns } from '../utils/workItemTable/getColumns';
import { getRows } from '../utils/workItemTable/getRows';
import { SilevisPortalRow, RowCells } from '../interfaces/reactgrid.interface';
import { WorkItemStatus } from '../enums/WorkItemStatus';
import { Loader } from '@portal/frontend/react';
import useArray from '../hooks/useArray';
import { ConfirmationModal, ModalData } from './ConfirmationModal';
import { hiddenWorkItemStatuses } from '../enums/HiddenWorkItemStatuses';

const modalInitialData: ModalData = {
  show: false,
  workItem: null,
  hasWorkItemExistingDependencies: false,
};

const changeMerger =
  <D extends { id: string }, T extends Record<string, unknown>>(
    id: string,
    collection: D[],
  ) =>
  (partialChange: Partial<T>) => {
    const isContainsItem = collection.find((item) => item.id === id);
    return isContainsItem
      ? collection.map((item) =>
          isContainsItem.id === item.id ? { ...item, ...partialChange } : item,
        )
      : [...collection, { id, ...partialChange }];
  };

export const WorkItemsTable = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [modalData, setModalData] = useState<ModalData>(modalInitialData);
  const [showActuals, setShowActuals] = useState<boolean>(false);
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [showEstimates, setShowEstimates] = useState<boolean>(true);
  const [workItems, setWorkItems, add, update, remove, updateMany] =
    useArray<WorkItem>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [rows, setRows] = useState<SilevisPortalRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [saving, setSaving] = useState(0);
  const [addingWorkItem, setAddingWorkItem] = useState(false);

  // TODO replace with single endpoint query
  const fetchWorkItems = useCallback(
    async () => await Axios.get(`${Endpoint.PROJECT_WORK_ITEMS}/${projectId}`),
    [projectId],
  );
  const fetchRoles = useCallback(
    async () => await Axios.get(`${Endpoint.PROJECT_ROLES}/${projectId}`),
    [projectId],
  );

  const fetchData = useCallback(async () => {
    if (!projectId) {
      return;
    }
    setIsLoading(true);
    Axios.all([fetchWorkItems().catch(), fetchRoles().catch()]).then(
      Axios.spread((workItemsResponse, rolesResponse) => {
        const filteredWorkItems = workItemsResponse.data.filter(
          (workItem) =>
            !hiddenWorkItemStatuses.some(
              (status) => status === workItem.status,
            ),
        );
        setWorkItems(filteredWorkItems.sort(sortByExtId));
        setRoles(rolesResponse.data);
        setIsLoading(false);
      }),
    );
  }, [projectId, fetchWorkItems, fetchRoles, setWorkItems]);

  useEffect(() => {
    fetchData().catch();
  }, [fetchData]);

  useEffect(() => {
    setShowArchived(false);
    setShowActuals(false);
  }, [projectId]);

  const hideColumns = useCallback(
    (hiddenColumns: ColumnsIds[]): Column[] => {
      return getColumns(roles).filter(
        (column) =>
          !hiddenColumns.some((ids) =>
            (column.columnId as string).includes(ids),
          ),
      );
    },
    [roles],
  );

  const getHiddenCol = useCallback((): ColumnsIds[] => {
    const hiddenCol = [];
    if (!showActuals) {
      hiddenCol.push(
        ColumnsIds.ACTUAL_HOURS,
        ColumnsIds.BUDGET_LEFT,
        ColumnsIds.ACTUAL_PRICE,
      );
    }
    if (!showEstimates) {
      hiddenCol.push(ColumnsIds.PLANNED_HOURS);
    }
    return hiddenCol;
  }, [showActuals, showEstimates]);

  useMemo(() => {
    const hiddenCol = getHiddenCol();
    setColumns(hideColumns(hiddenCol));

    const rows = getRows(workItems, roles, hiddenCol);
    setRows(saving > 0 ? makeCellsNonEditable(rows) : rows);
  }, [getHiddenCol, hideColumns, workItems, roles, saving]);

  if (isLoading) {
    return <Loader fullScreen></Loader>;
  }

  const handleOnChangeShowActuals = (show: boolean) => {
    setShowActuals(show);
  };

  const handleOnChangeShowArchived = async (show: boolean) => {
    setShowArchived(show);
    const { data } = await fetchWorkItems().catch();

    if (show) {
      setWorkItems(data.sort(sortByExtId));
    } else {
      setWorkItems(
        filterDataByStatuses(data.sort(sortByExtId), hiddenWorkItemStatuses),
      );
    }
  };

  const handleOnChangeShowEstimates = async (show: boolean) => {
    setShowEstimates(show);
  };

  const handleAddWorkItem = async () => {
    setAddingWorkItem(true);

    try {
      const { data } = await Axios.post<
        CreateWorkItemDto,
        AxiosResponse<WorkItem>
      >(Endpoint.WORK_ITEMS, {
        name: '',
        extId: '',
        projectId: projectId,
        progress: 0,
        status: WorkItemStatus.IDEA,
        startDate: new Date(),
        deadLine: new Date(),
      });
      add(data);
    } catch (error) {
      console.error(error);
    } finally {
      setAddingWorkItem(false);
    }
  };

  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const handleUpdateWorkItem = (updateWorkItemsDto: UpdateWorkItemDto[]) => {
    sendRequest<UpdateWorkItemsDto>(
      Endpoint.WORK_ITEMS,
      { workItems: updateWorkItemsDto },
      'PATCH',
    )
      .then(() => {
        updateWorkItemsDto.forEach((workItem) => {
          update({
            ...workItems.find((w) => w.id === workItem.id),
            ...workItem,
          });
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSaving((saving) => --saving);
      });
  };

  const handleUpdateWorkItemRoleHours = (
    workItemRoleHours: UpdateWorkItemRoleHoursPayloadDto[],
  ) => {
    sendRequest<
      UpdateWorkItemRoleHoursPlannedHoursDto,
      UpsertWorkItemRoleHoursWithPlannedHoursResponseDto
    >(
      Endpoint.WORKITEM_ROLE_HOURS,
      { workItemRoleHoursPlannedHours: workItemRoleHours },
      'PATCH',
    )
      .then(({ data: { workItemChanges } }) => {
        let updatedWorkItems: WorkItem[] = [];

        workItemChanges.forEach((workItemChange) => {
          const workItem = workItems.find(
            (workItem) => workItem.id === workItemChange.workItemId,
          );
          const updatedWorkItem = updatedWorkItems.find(
            (workItem) => workItem.id === workItemChange.workItemId,
          );
          const role = roles.find((role) => role.id === workItemChange.roleId);
          const workItemRoleHoursSource = (
            updatedWorkItem ? updatedWorkItem : workItem
          ).workItemRoleHours;

          let updatedWorkItemRoleHours: WorkItem['workItemRoleHours'] = [
            ...workItemRoleHoursSource,
          ];

          if (workItemChange.created) {
            const { plannedHours, workItemRoleHoursId } =
              workItemChange.created;
            updatedWorkItemRoleHours = [
              ...updatedWorkItemRoleHours,
              {
                id: workItemRoleHoursId,
                role,
                actualHours: 0,
                plannedHours,
              },
            ];
          }

          if (workItemChange.updated) {
            const { plannedHours, workItemRoleHoursId } =
              workItemChange.updated;
            updatedWorkItemRoleHours = updatedWorkItemRoleHours.map(
              (workItemRoleHour) =>
                workItemRoleHour.id === workItemRoleHoursId
                  ? { ...workItemRoleHour, plannedHours }
                  : workItemRoleHour,
            );
          }

          const isExists = !!updatedWorkItems.find(
            (item) => item.id === workItem.id,
          );

          updatedWorkItems = isExists
            ? updatedWorkItems.map((updatedWorkItem) =>
                updatedWorkItem.id === workItem.id
                  ? {
                      ...updatedWorkItem,
                      workItemRoleHours: updatedWorkItemRoleHours,
                    }
                  : updatedWorkItem,
              )
            : [
                ...updatedWorkItems,
                { ...workItem, workItemRoleHours: updatedWorkItemRoleHours },
              ];
        });

        updateMany(updatedWorkItems);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSaving((saving) => --saving);
      });
  };

  const handleArchiveWorkitem = (id: Id) => {
    Axios.delete(`${Endpoint.WORK_ITEMS}/${id}`)
      .then((_) => {
        remove(id);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setModalData(modalInitialData);
        setSaving((saving) => --saving);
      });
  };

  const filterDataByStatuses = (
    workItems: WorkItem[],
    statuses: WorkItemStatus[],
  ): WorkItem[] => {
    return workItems.filter(
      (workItem) => !statuses.some((status) => workItem.status === status),
    );
  };

  const handleChanges = async (changes: CellChange<RowCells>[]) => {
    let workItemsToUpdate: UpdateWorkItemDto[] = [];
    let plannedHoursToUpdate: UpdateWorkItemRoleHoursPayloadDto[] = [];

    setSaving((saving) => ++saving);

    changes.forEach((change) => {
      const workItemId = change.rowId as string;
      const updatedWorkItem = {
        ...workItems.find((workItem) => workItem.id === workItemId),
      };

      if (change.columnId === ColumnsIds.ARCHIVE) {
        setModalData({
          show: true,
          workItem: updatedWorkItem,
          hasWorkItemExistingDependencies:
            updatedWorkItem.workItemRoleHours.some(
              (workItemRoleHour) => workItemRoleHour.actualHours > 0,
            ),
        });
      }

      const applyPartialUpdate = changeMerger(
        updatedWorkItem.id,
        workItemsToUpdate,
      );

      if (
        change.columnId === ColumnsIds.NAME &&
        change.type === 'extendedText'
      ) {
        workItemsToUpdate = applyPartialUpdate({ name: change.newCell.text });
      }
      if (change.columnId === ColumnsIds.EXT_ID && change.type === 'text') {
        workItemsToUpdate = applyPartialUpdate({ extId: change.newCell.text });
      }
      if (change.columnId === ColumnsIds.PROGRESS && change.type === 'number') {
        workItemsToUpdate = applyPartialUpdate({
          progress: change.newCell.value,
        });
      }
      if (
        change.columnId === ColumnsIds.STATUS &&
        change.type === 'customDropdown' &&
        change.newCell.text !== ''
      ) {
        workItemsToUpdate = applyPartialUpdate({
          status: change.newCell.text as WorkItemStatus,
        });
      }
      if (change.columnId === ColumnsIds.START_DATE && change.type === 'date') {
        workItemsToUpdate = applyPartialUpdate({
          startDate: change.newCell.date,
        });
      }
      if (change.columnId === ColumnsIds.DEADLINE && change.type === 'date') {
        workItemsToUpdate = applyPartialUpdate({
          deadLine: change.newCell.date,
        });
      }
      const [roleId, columnName] = change.columnId.toString().split(':');
      if (columnName === ColumnsIds.PLANNED_HOURS && change.type === 'number') {
        const plannedHours = change.newCell.value || 0;
        const isWorkItemExists = plannedHoursToUpdate.find(
          (item) => item.workItemId === workItemId,
        );
        plannedHoursToUpdate = isWorkItemExists
          ? plannedHoursToUpdate.map<UpdateWorkItemRoleHoursPayloadDto>(
              (plannedWorkHours) =>
                plannedWorkHours.workItemId === workItemId
                  ? {
                      ...plannedWorkHours,
                      roleHours: [
                        ...plannedWorkHours.roleHours,
                        { roleId, plannedHours },
                      ],
                    }
                  : plannedWorkHours,
            )
          : [
              ...plannedHoursToUpdate,
              { workItemId, roleHours: [{ roleId, plannedHours }] },
            ];
      }
    });

    if (workItemsToUpdate.length > 0) {
      handleUpdateWorkItem(workItemsToUpdate);
    }

    if (plannedHoursToUpdate.length > 0) {
      handleUpdateWorkItemRoleHours(plannedHoursToUpdate);
    }
  };

  return (
    <>
      <div className="pb-3 d-flex">
        <ToggleSwitch
          name="Show Estimates"
          onChange={handleOnChangeShowEstimates}
          checked={showEstimates}
        />

        <ToggleSwitch
          name="Show Actuals"
          onChange={handleOnChangeShowActuals}
          checked={showActuals}
        />

        <ToggleSwitch
          name="Show Archived"
          onChange={handleOnChangeShowArchived}
          checked={showArchived}
        />
      </div>

      <div className="d-flex overflow-hidden">
        <div className="overflow-auto">
          <LoadingOverlay show={saving > 0}>
            <ReactGrid
              rows={rows}
              columns={columns}
              stickyTopRows={1}
              onCellsChanged={handleChanges}
              enableRangeSelection
              enableFillHandle
              onColumnResized={handleColumnResize}
              customCellTemplates={{
                customDropdown: new CustomDropdownCellTemplate(),
                archiveButton: new ArchiveButtonCellTemplate(),
                extendedText: new ExtendedTextCellTemplate(),
              }}
            />
          </LoadingOverlay>
        </div>
      </div>

      <div className="pt-3">
        <Button
          variant="primary"
          size="sm"
          onClick={handleAddWorkItem}
          disabled={!projectId ? true : addingWorkItem}
        >
          <ActionSpinner
            showSpinner={addingWorkItem}
            defaultText={'Add Work Item'}
            spinnerContent={'Adding work item ...'}
          />
        </Button>
      </div>

      <ConfirmationModal
        modalData={modalData}
        onHide={() => {
          setModalData(modalInitialData);
          setSaving((saving) => --saving);
        }}
        onConfirm={(workItemId) => handleArchiveWorkitem(workItemId)}
      />
    </>
  );
};

export default WorkItemsTable;
