import { CellStyle, HeaderCell, Row } from '@silevis/reactgrid';
import moment from 'moment';
import { Moment } from 'moment-business-days';
import { getChangeWeekCell, getHeaderCell } from '../templates/cellGetters';
import { ChangeWeekCell } from '../templates/ChangeWeekCell';
import { getDaysCurrentWeek } from './getDaysCurrentWeek';

const style: CellStyle = {
  border: {
    left: { color: 'green', style: 'solid', width: '2px' },
    top: { color: 'green', style: 'solid', width: '2px' },
    right: { color: 'green', style: 'solid', width: '2px' },
  },
};

export const style2: CellStyle = {
  border: {
    left: { color: 'green', style: 'solid', width: '2px' },
    right: { color: 'green', style: 'solid', width: '2px' },
  },
};

export const getDaysOfWeekRows = (
  days: Moment[],
  setDaysOfWeek: React.Dispatch<React.SetStateAction<Moment[]>>,
): Row<HeaderCell | ChangeWeekCell>[] => {
  return [
    {
      rowId: '0-days',
      height: 35,
      cells: [
        getHeaderCell(''),
        getHeaderCell(days[6].format('MMMM YYYY')),
        getChangeWeekCell(true, () => {
          const lastDayPrevWeek = days[0];
          const daysImPrevWeek = getDaysCurrentWeek(
            lastDayPrevWeek.subtract(1, 'd'),
          );
          setDaysOfWeek(daysImPrevWeek);
        }),
        ...days.map((day): HeaderCell => {
          return getHeaderCell(
            day.format('D'),
            moment(day).format('DD-MM-YYYY') ===
              moment(new Date()).format('DD-MM-YYYY')
              ? style
              : {},
            'justify-content-center',
          );
        }),
        getChangeWeekCell(false, () => {
          const firstDayNextWeek = days[days.length - 1];
          const daysInNextWeek = getDaysCurrentWeek(
            firstDayNextWeek.add(1, 'd'),
          );
          setDaysOfWeek(daysInNextWeek);
        }),
      ],
    },
    {
      rowId: '1-days-name',
      height: 35,
      cells: [
        getHeaderCell('Name'),
        getHeaderCell('ID'),
        getHeaderCell(''),
        ...days.map((day): HeaderCell => {
          return getHeaderCell(
            day.format('ddd'),
            moment(day).format('DD-MM-YYYY') ===
              moment(new Date()).format('DD-MM-YYYY')
              ? style2
              : {},
            'justify-content-center',
          );
        }),
        getHeaderCell(''),
      ],
    },
  ];
};
