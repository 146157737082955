import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import WorkItemsTable from '../components/WorkItemsTable';
import Sidebar from '../components/Sidebar';
import getPathnameSegment from '../utils/getPathnameSegment';
import { useProjects } from '../hooks/useProjects';
import { Loader } from '@portal/frontend/react';

export const WorkItemsView = () => {
  const { projects, isValidating } = useProjects();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!getPathnameSegment(1) && projects) {
      navigate(`${location.pathname}/${projects[0].id}`);
    }
  }, [projects, navigate, location.pathname]); // `history.location.pathname` is required

  if (isValidating || !projects) {
    return <Loader fullScreen />;
  }

  const nonArchivedProjects = projects.filter((project) => !project.deletedAt);

  return (
    <Routes>
      <Route
        path={`:projectId`}
        element={
          <>
            <Col md={2} xs={12} className="p-0 h-100">
              <Sidebar projects={nonArchivedProjects} />
            </Col>

            <Col
              md={10}
              xs={12}
              className="p-5 bg-light d-flex flex-column h-100"
            >
              <WorkItemsTable />
            </Col>
          </>
        }
      />
    </Routes>
  );
};
