import React, { useEffect, useState } from 'react';
import { CellChange, Id, ReactGrid, Row } from '@silevis/reactgrid';
import moment from 'moment';
import { Moment } from 'moment-business-days';
import {
  getWorkItemDescriptionRows,
  ProjectsModel,
} from '../functions/getWorkItemDescriptionRows';
import { getColumns } from '../functions/getDescriptionTableColumns';
import { useEmployeeProjects } from '../hooks/useEmployeeProjects';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { UpdateTimetableEntries } from 'libs/developer-portal-api-model/src/lib/dto/updateTimetableEntries.dto';
import { sendRequest } from 'libs/frontend/utils/src/lib/sendRequest';
import { TimetableEntry } from 'libs/database-entities/src/lib/entities/timetableEntry.entity';
import { LoadingOverlay } from './LoadingOverlay';
import { NonEditableChevronCellTemplate } from '../templates/NonEditableChevronCell';
import {
  ExtendedHeaderCell,
  ExtendedHeaderCellTemplate,
} from '../templates/ExtendedHeaderCellTemplate';
import { TextAreaCellTemplate } from '../templates/TextAreaCellTemplate';
import { makeCellsNonEditable } from '../functions/makeCellsNonEditable';

import { useModal } from '../hooks/useModal';
import ChangeWorkPackageModal from './ChangeWorkPackageModal';
import { WorkItem } from '../interfaces/WorkItem.interface';
import { Project } from '../interfaces/Project.interface';
import { TimetableEntryReassignDto } from '@portal/developer-portal-api-model';
import {
  CellValues,
  ChangePackageWorkItemId,
} from '../interfaces/ChangeWorkItem.interface';
import { ProjectWorkDay } from '../interfaces/WorkDay.interface';
import {
  DeveloperPortalCellTypes,
  WorkItemsRowCell,
} from '../interfaces/reactgrid.interface';
import Endpoint from '../enums/apiEndpoints';

interface WorkItemsDescriptionTableProps {
  employeeId: string;
  daysOfWeek: Moment[];
}

export const isWorkItemActiveInWorkDay =
  (workItem: WorkItem) => (day: Moment) =>
    workItem.timetableEntries.some(
      (timetableEntry) =>
        timetableEntry.hours !== null &&
        moment(timetableEntry.date).isSame(day, 'date'),
    );

const isProjectRow = (rowId: Id): boolean => {
  const rowIdSegments = rowId.toString().split(';');
  return rowIdSegments.length === 2;
};

const checkIdsCompatibility = (
  owd: ProjectWorkDay,
  projectId: string,
  rowId: Id,
): boolean => {
  const idToCompare = owd.day.toString() + (projectId ? `;${projectId}` : '');
  return idToCompare === rowId;
};

export const WorkItemsDescriptionTable: React.FC<
  WorkItemsDescriptionTableProps
> = ({ employeeId, daysOfWeek }) => {
  const [saving, setSaving] = useState(0);
  const [isVisible, open, close] = useModal();
  const [workItemToReassign, setWorkItemToReassign] =
    useState<CellValues | null>();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const showRecentTasks = searchParams.get('showRecentTasks') === 'true';

  const { employeeProjects, mutateEmployeeProjects } = useEmployeeProjects(
    employeeId,
    showRecentTasks,
  );

  const [workDays, setWorkDays] = useState(() =>
    daysOfWeek.map<ProjectWorkDay>((dow) => ({
      day: dow,
      hidden: false,
      hiddenProjectsIds: [],
    })),
  );

  useEffect(() => {
    const workDaysInit = daysOfWeek.map((dow) => ({
      day: dow,
      hidden: false,
      hiddenProjectsIds: [],
    }));
    setWorkDays(workDaysInit);
  }, [daysOfWeek]);

  const cellChangesHandler = (
    changes: CellChange<DeveloperPortalCellTypes>[],
  ) => {
    changes.forEach((change) => {
      if (
        change.columnId === 'day-in-week' &&
        (change.type === 'chevron' || change.type === 'nonEditableChevron')
      ) {
        setWorkDays((oldWorkDays) =>
          oldWorkDays.map((oldWorkDay) => {
            const [date, projectId] = change.rowId.toString().split(';');
            return isProjectRow(change.rowId)
              ? {
                  ...oldWorkDay,
                  hiddenProjectsIds:
                    checkIdsCompatibility(
                      oldWorkDay,
                      projectId,
                      change.rowId,
                    ) && moment(date).isSame(oldWorkDay.day, 'date')
                      ? !oldWorkDay.hiddenProjectsIds.includes(projectId)
                        ? [...oldWorkDay.hiddenProjectsIds, projectId]
                        : oldWorkDay.hiddenProjectsIds.filter(
                            (hiddenProjectId) => hiddenProjectId !== projectId,
                          )
                      : [...oldWorkDay.hiddenProjectsIds],
                }
              : {
                  ...oldWorkDay,
                  hidden: checkIdsCompatibility(
                    oldWorkDay,
                    projectId,
                    change.rowId,
                  )
                    ? !change.newCell.isExpanded
                    : oldWorkDay.hidden,
                };
          }),
        );
        return;
      }

      if (
        (change.columnId === 'description' && change.type === 'text') ||
        change.type === 'textArea'
      ) {
        const workHoursCell = change.newCell as WorkItemsRowCell;
        setSaving((saving) => ++saving);

        sendRequest<UpdateTimetableEntries, TimetableEntry>(
          `${Endpoint.TIMETABLE_ENTRIES_UPDATE}/${workHoursCell.timetableEntryId}`,
          { description: change.newCell.text },
          'POST',
        )
          .then(({ status, data }) => {
            if (status === 201) {
              mutateEmployeeProjects(
                (prevProjects) =>
                  prevProjects.map((project) => ({
                    ...project,
                    workItems: project.workItems.map((workItem) => ({
                      ...workItem,
                      timetableEntries: workItem.timetableEntries.map(
                        (timetableEntry) =>
                          timetableEntry.id === data.id
                            ? {
                                ...timetableEntry,
                                description: data.description,
                              }
                            : timetableEntry,
                      ),
                    })),
                  })),
                true,
              );
            }
          })
          .catch(() => {
            toast.error('An error occurred while saving');
          })
          .finally(() => {
            setSaving((saving) => --saving);
          });
      }
    });
  };

  const workItems =
    employeeProjects?.flatMap((project) =>
      project.workItems.flatMap((workItems) => workItems),
    ) ?? [];

  const activeWorkDays = workDays.filter((workDay) =>
    workItems.some((workItem) =>
      isWorkItemActiveInWorkDay(workItem)(workDay.day),
    ),
  );

  const data = activeWorkDays
    .sort((a, b) => (a.day < b.day ? 1 : -1))
    .map<ProjectsModel>((date) => ({
      projectWorkDay: date,
      projects: employeeProjects
        .filter((project) =>
          project.workItems.some((workItem) =>
            isWorkItemActiveInWorkDay(workItem)(date.day),
          ),
        )
        .map<Project>((project) => ({
          ...project,
          workItems: project.workItems.filter((workItem) =>
            isWorkItemActiveInWorkDay(workItem)(date.day),
          ),
        })),
    }));

  const workItemDescriptionRows = data.flatMap<Row<DeveloperPortalCellTypes>>(
    (data) => getWorkItemDescriptionRows(data),
  );

  const openModal = (cell: ExtendedHeaderCell) => {
    setWorkItemToReassign(cell);
    open();
  };

  const hideModal = () => {
    setWorkItemToReassign(null);
    close();
  };

  const saveHours = async (values: ChangePackageWorkItemId) => {
    setSaving((saving) => ++saving);
    sendRequest<TimetableEntryReassignDto, TimetableEntry>(
      `${Endpoint.CHANGE_WORK_HOUR}/${workItemToReassign.meta.workHourId}`,
      {
        employeeId,
        targetWorkItemId: values.workItemId,
      },
      'POST',
    )
      .then(({ status, data }) => {
        if (status === 201) {
          mutateEmployeeProjects(
            (prevProjects) =>
              prevProjects.map((project) => ({
                ...project,
                workItems: project.workItems.map((workItem) => ({
                  ...workItem,
                  timetableEntries: workItem.timetableEntries.map(
                    (timetableEntry) =>
                      timetableEntry.id === data.id
                        ? {
                            ...timetableEntry,
                            date: new Date(data.date).toISOString(),
                          }
                        : timetableEntry,
                  ),
                })),
              })),
            true,
          );
        }
      })
      .catch(() => {
        toast.error('An error occurred while saving');
      })
      .finally(() => {
        setSaving((saving) => --saving);
      });
    setWorkItemToReassign(null);
    close();
  };

  return (
    <div className="position-relative">
      <LoadingOverlay show={saving > 0}>
        {workItemDescriptionRows && workItemDescriptionRows.length > 0 && (
          <ReactGrid
            columns={getColumns()}
            customCellTemplates={{
              nonEditableChevron: new NonEditableChevronCellTemplate(),
              extendedHeader: new ExtendedHeaderCellTemplate({ openModal }),
              textArea: new TextAreaCellTemplate(),
            }}
            onCellsChanged={cellChangesHandler}
            rows={[
              ...(saving > 0
                ? makeCellsNonEditable(workItemDescriptionRows)
                : workItemDescriptionRows),
            ]}
            enableRangeSelection
            enableFillHandle
          />
        )}
        {workItemToReassign?.meta && (
          <ChangeWorkPackageModal
            projectDetails={{
              label: workItemToReassign.meta.projectName,
              value: workItemToReassign.meta.projectId,
            }}
            workItemDetails={{
              label: workItemToReassign.text,
              value: workItemToReassign.meta.workItemId,
            }}
            employeeId={employeeId}
            onSave={saveHours}
            onHide={hideModal}
            show={isVisible}
          />
        )}
      </LoadingOverlay>
    </div>
  );
};
